import React, { useState } from 'react'
import { Container, Image } from 'react-bootstrap'
import styled from 'styled-components'
import ImageLivreur from "../assets/livreur1.webp"
import ImageMDP from "../assets/motDePasse.webp"

const Wrapper = styled(Container)`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    background-color: white;
    padding: 0;
`
const CadreLeft = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 40%;
    background-color: white;
    @media (max-width: 496px) {
        width: 100%;
    }
`
const CadreRight = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 60%;
    background-color: white;
    @media (max-width: 496px) {
        width: 100%;
    }
`
const Title = styled.h1`
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: 900;
    margin-top: 4rem;
    @media (max-width: 600px) {
        margin-top: 10px;
    }
`
const Home = styled.a`
    padding: 0.75em;
    text-decoration: none;
    height: 55px;
    width: 60%;
    text-align: center;
    font-weight: bold;
    font-size: 1.2em;
    text-transform: uppercase;
    color: #fff;
    background-color: #fd9f1b;
    border-radius: 10px;
    border: 0;
    transition: all 0.1s ease-in-out;
    &:hover {
        background-color: #fff;
        color: #fd9f1b;
        background-position: -3.75em;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    }
    @media (max-width: 600px) {
        height: 45px;
        font-size: 0.8em;
    }
    @media (max-width: 496px) {
        width: 80%;
    }
    @media (max-width: 400px) {
        width: 90%;
    }
`
export const UpDatePassWord = () => {

    return (
        <Wrapper fluid>
            <CadreLeft>
                <Image style = {{width:"100%", height:"100%"}} src = {ImageLivreur} alt = "Livreur GOChap"/>
            </CadreLeft>
            <CadreRight>
                <Title>Votre mot de passe a été mis à jour</Title>
                <Image style = {{width:"50%", height:"50%"}} src = {ImageMDP} alt = "Opération réussie"/>
                <Home className='mb-5' href='/'>Page d'accueil</Home>
            </CadreRight>
        </Wrapper>
    )
}
