import React from 'react'
import styled from 'styled-components'
import Error from '../assets/erreur.webp'

const Wrapper = styled.div`
    overflow: hidden;
`
const Landscape = styled.div`
    padding: 0px;
    margin: 0px;
    background: #2d2d2d !important;
    width: 100%;
    height: 70vh;
    position: relative;
    z-index: -1;
`
const LandscapeWrapper = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    padding: 5%;
    color: #fff;
    line-height: 1.5em;
    top: 0;
    left: 0;
`
const LandscapeContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    @media (max-width: 425px) {
       flex-direction: column;
    }
`
const ErrorImage = styled.img`
    @media (max-width: 570px) {
       height: 200px !important;
       width: 200px !important;
    }
`

export default function Erreur() {
    return (
        <Wrapper>
            <Landscape>
                <LandscapeWrapper>
                    <LandscapeContainer>
                        <div>
                            <p className="fw-bold text-capitalize fs-1 lh-sm">
                                Page introuvable
                            </p>
                        </div>
                        <div>
                            <ErrorImage src={Error} alt="Page Introuvable" style={{height: 300, width: 300}}/>
                        </div>
                    </LandscapeContainer>
                </LandscapeWrapper>
            </Landscape>
        </Wrapper>
    )
}
