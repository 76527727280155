import React, { Suspense, lazy, useTransition } from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import GlobalStyle from './utils/style/index.jsx'
import 'bootstrap/dist/css/bootstrap.min.css'
import './utils/style/index.scss'
import ScrollToTop from './utils/helpers/ScrollToTop.jsx'
import { EndSignUp } from './components/EndSignUp.jsx'
import { MotDePasse } from './pages/MotDePasse.jsx'
import { CodeDeConfirmation } from './pages/CodeDeConfirmation.jsx'
import { NouveauMotDePasse } from './pages/NouveauMotDePasse.jsx'
import { UpDatePassWord } from './pages/UpDatePassWord.jsx'
import Error from './components/Erreur.jsx'
import SkeletonLoader from 'tiny-skeleton-loader-react'
import { CountdownProvider } from './utils/context/index.jsx'
import PolitiquesDeConfidentalite from './pages/PolitiquesDeConfidentialite.jsx'

const Home = lazy(() => import('./pages/Home'))
const Footer = lazy(() => import('./components/Footer'))
const Header = lazy(() => import('./components/Header'))
const DevenirLivreur = lazy(() => import('./pages/DevenirLivreur.jsx'))
const APropos = lazy(() => import('./pages/APropos.jsx'))
const Faq = lazy(() => import('./pages/Faq.jsx'))
const CommentCaFonctionne = lazy(() =>
    import('./pages/CommentCaFonctionne.jsx')
)
const DevenirPartenaire = lazy(() => import('./pages/DevenirPartenaire.jsx'))
const ConditionsGeneralesDUtilisation = lazy(() =>
    import('./pages/ConditionsGeneralesDUtilisation.jsx')
)
const ConditionsGeneralesDeVente = lazy(() =>
    import('./pages/ConditionsGeneralesDeVente.jsx')
)

const App = () => {
    const [startTransition, isPending] = useTransition({
        timeoutMs: 1000, // Set a timeout for the transition (optional)
    })

    return (
        <React.StrictMode>
            <Router>
                <CountdownProvider>
                    <GlobalStyle />
                    <Header />
                    <ScrollToTop />
                    <Suspense fallback={<SkeletonLoader />}>
                        <Routes>
                            <Route exact path="/" element={<Home />} />
                            <Route
                                path="/devenir-livreur"
                                element={<DevenirLivreur />}
                            />
                            <Route path="/a-propos" element={<APropos />} />
                            <Route path="/faq" element={<Faq />} />
                            <Route
                                path="/comment-ca-fonctionne"
                                element={<CommentCaFonctionne />}
                            />
                            <Route
                                path="/devenir-partenaire"
                                element={<DevenirPartenaire />}
                            />
                            <Route
                                path="/politiques-de-confidentalite"
                                element={<PolitiquesDeConfidentalite />}
                            />
                            <Route
                                path="/conditions-generales-d-utilisation"
                                element={<ConditionsGeneralesDUtilisation />}
                            />
                            <Route
                                path="/conditions-generales-de-vente"
                                element={<ConditionsGeneralesDeVente />}
                            />
                            <Route path="*" element={<Error />} />

                            <Route
                                path="/mot-de-passe"
                                element={<MotDePasse />}
                            />
                            <Route
                                path="/code-de-confirmation"
                                element={<CodeDeConfirmation />}
                            />
                            <Route
                                path="/nouveau-mot-de-passe"
                                element={<NouveauMotDePasse />}
                            />
                            <Route
                                path="/mot-de-passe-mis-a-jour"
                                element={<UpDatePassWord />}
                            />
                            {/* <Route
                path="/inscription-livreur"
                element={<EndSignUp />}
                /> */}
                        </Routes>
                    </Suspense>
                    <Footer />
                </CountdownProvider>
            </Router>
        </React.StrictMode>
    )
}

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(<App />)
