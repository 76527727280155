import React, { useState } from 'react'
import { Container, Image } from 'react-bootstrap'
import styled from 'styled-components'
import ImageLivreur from "../assets/livreur1.webp"
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { BsEye, BsEyeSlash } from "react-icons/bs"
import  jwtDecode  from "jwt-decode"
import { JWT_SECRET } from '../utils/config'
import { useParams } from 'react-router-dom'

const Wrapper = styled(Container)`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    background-color: white;
    padding: 0;
`
const Title = styled.h1`
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: 900;
    @media (max-width: 600px) {
        margin-top: 10px;
    }
`
const Description = styled.p`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60%;
    text-align: center;
`

const CadreLeft = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 40%;
    background-color: white;
    @media (max-width: 496px) {
        width: 100%;
    }
`
const CadreRight = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 60%;
    background-color: white;
    @media (max-width: 496px) {
        width: 100%;
    }
`

const MyForm = styled.form`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`
const DivRow = styled.div`
    width: 100%;
    display: flex;
    align-items: start;
    justify-content: center;
`
const FormItems = styled.div`
    display: flex;
    width: 60%;
    margin-bottom: 10px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    @media (max-width: 496px) {
        width: 80%;
    }
    @media (max-width: 400px) {
        width: 90%;
    }
`
const FormInput = styled.input`
    margin-top: 5px;
    height: 55px;
    width: 100%;
    background-color: #EEEEEE;
    border-radius: 15px;
    padding-left: 10px;
    border: 0;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    @media (max-width: 600px) {
        height: 45px;
    }
`
const Submit = styled.button`
    display: flex;
    margin-right: 25px;
    padding: 0.75em;
    height: 55px;
    width: 60%;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 1.2em;
    text-transform: uppercase;
    color: #fff;
    background-color: #fd9f1b;
    border-radius: 10px;
    border: 0;
    transition: all 0.1s ease-in-out;
    &:hover {
        background-color: #fff;
        color: #fd9f1b;
        background-position: -3.75em;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    }
    @media (max-width: 600px) {
        font-size: 0.8em;
        height: 45px;
    }
    @media (max-width: 496px) {
        width: 80%;
    }
    @media (max-width: 400px) {
        width: 90%;
    }
`
const ButtonShow = styled.button`
    border: none;
    background: transparent;
`


const validationSchema = Yup.object().shape({
    motDePasse: Yup.string()
        .required('Veuillez entrer un mot de passe')
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})/,
            'Doit contenir au moins 6 caractères, une majuscule, une minuscule et un nombre'
        ),
    confirmerMotDePasse: Yup.string()
        .test('correspondance-mot-de-passe', 'Les mots de passe ne correspondent pas', function(value) {
            const motDePasse = this.resolve(Yup.ref('motDePasse'));
            if (!motDePasse) {
                return true;
            }
            return value === motDePasse;
        })
        .when('motDePasse', {
            is: (motDePasse) => motDePasse && motDePasse.length > 0,
            then: Yup.string().required('Veuillez confirmer votre mot de passe'),
          }),
})

const Error = styled.p`
    font-size: 0.8em;
    color: red;
    margin-left: 10px;
    margin-top: 10px;
`

const initialValues = {
    motDePasse: '',
    confirmerMotDePasse: '',
}


export const NouveauMotDePasse = () => {
    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values) => {
            console.log('MyForm data', values)
        },
    })
    const [motDePasse, setMotDePasse] = useState('')
    const [showPassword1, setShowPassword1] = useState(false)
    const [showPassword2, setShowPassword2] = useState(false)
    const [tokenValid, setTokenValid] = useState(false)

    const params = useParams()
    
    //const decode = jwtDecode(params.token)

    console.log(params)
    
    return (
        <Wrapper fluid>
            <CadreLeft>
                <Image style = {{width:"100%", height:"100%"}} src = {ImageLivreur} alt = "Livreur GOChap"/>
            </CadreLeft>
            <CadreRight>
                <Title>Nouveau Mot De Passe</Title>
                <Description>Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien.</Description>
                <MyForm
                    onSubmit={formik.handleSubmit}
                    className="mt-2 mb-5"
                >
                    <DivRow>
                        <FormItems className="mt-3">
                            <FormInput
                                placeholder="Entrez le nouveau mot de passe"
                                name="motDePasse"
                                id="motDePasse"
                                type={showPassword1 ? 'text' : 'password'}
                                onChange={(e) => setMotDePasse(e.target.value)}
                                value={motDePasse}
                                {...formik.getFieldProps('motDePasse')}
                                required
                            />
                            {formik.errors.motDePasse &&
                                formik.touched.motDePasse && (
                                    <Error>
                                        {formik.errors.motDePasse}
                                    </Error>
                                )
                            }

                        </FormItems>
                        <ButtonShow
                            type="button"
                            onClick={() => setShowPassword1(!showPassword1)}
                        >
                            {showPassword1 ? <BsEye/> : <BsEyeSlash/>}
                        </ButtonShow>
                    </DivRow>
                    <DivRow>
                        <FormItems className="mt-1">
                            <FormInput
                                style={{ backgroundColor: '#E8E8E8' }}
                                placeholder="Confirmez le nouveau mot de passe"
                                name="confirmerMotDePasse"
                                id="confirmerMotDePasse"
                                type={showPassword2 ? 'text' : 'password'}
                                {...formik.getFieldProps('confirmerMotDePasse')}
                                required
                            />
                            {formik.errors.confirmerMotDePasse &&
                                formik.touched.confirmerMotDePasse && (
                                    <Error>
                                        {formik.errors.confirmerMotDePasse}
                                    </Error>
                                )
                            }
                        </FormItems>
                        <ButtonShow
                            type="button"
                            onClick={() => setShowPassword2(!showPassword2)}
                            >
                            {showPassword2 ? <BsEye/> : <BsEyeSlash/>}
                        </ButtonShow>
                    </DivRow>
                    <Submit className="mt-1" type="submit">
                        Valider
                    </Submit>
                </MyForm>
            </CadreRight>
        </Wrapper>
    )
}


