import { createGlobalStyle } from 'styled-components'

const StyledGlobalStyle = createGlobalStyle`
    * {
        font-family: 'Montserrat','Trebuchet MS', 'Inter', 'Lucida Bright', sans-serif;
    }
    body {
        width: 100%;
        margin: 0px;
        background-color: #fff;
        overflow-x: hidden;
        scroll-behavior: smooth;
    }
`

function GlobalStyle() {
    return <StyledGlobalStyle />
}

export default GlobalStyle
