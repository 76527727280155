import React, { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import styled from 'styled-components'

const Wrapper = styled(Row)`
    width: 70%;
    margin-bottom: 30px;
    background-color: rgba(0, 72, 255, 0.16);
    @media (max-width: 992px) {
        width: 80%;
    }
    @media (max-width: 500px) {
        width: 90%;
    }
`
const FormItems = styled.div`
    display: flex;
    width: 80%;
    margin-bottom: 15px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    @media (max-width: 800px) {
        width: 90%;
    }
    @media (max-width: 400px) {
        width: 97%;
    }
`
const FormInput = styled.input`
    margin-top: 5px;
    height: 50px;
    width: 100%;
    border-radius: 10px;
    padding-left: 10px;
    border: 0;
    //box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
`
const Submit = styled.button`
    padding: 0.45em;
    text-decoration: none;
    text-align: center;
    font-weight: bold;
    font-size: 1.2em;
    color: #fff;
    background-color: #fd9f1b;
    width: 100%;
    border-radius: 10px;
    border: 0;
    transition: all 0.1s ease-in-out;
    &:hover {
        background-color: #fff;
        color: #fd9f1b;
        //transform: scale(1.05);
        background-position: -3.75em;
        //box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    }
`
const Error = styled.p`
    font-size: 0.8em;
    color: red;
    margin-left: 10px;
    margin-top: 10px;
`
const DivSubmit = styled.div`
        width: 80%;
        @media (max-width: 800px) {
            width: 90%;
        }
        @media (max-width: 400px) {
            width: 97%;
        }
`

export const Newsletter = () => {
    const EmailType =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/

    const [email, setEmail] = useState('')
    const [errorEmail, setEmailError] = useState('')

    const emailValidator = () => {
        if (email === '') {
            setEmailError('Veuillez entrer une adresse e-mail')
            return false
        } else if (!EmailType.test(email)) {
            setEmailError('Veuillez entrer une adresse e-mail correcte')
            return false
        } else {
            setEmailError('')
            return true
        }
    }

    return (
        <Container
            fluid
            className="d-flex align-items-center justify-content-center my-5"
        >
            <Wrapper>
                <Col className="px-5 py-2 d-flex flex-column justify-content-center">
                    <p className="fw-bold mb-2 fs-2">
                        Inscrivez-vous à notre newsletter
                    </p>
                    <p>
                        Pour ne rien manquer de notre actualité et surtout,
                        recevoir des contenus utiles : inscrivez-vous dès
                        maintenant à notre newsletter !
                    </p>
                </Col>
                <Col className="px-5 py-4 d-flex flex-column justify-content-center">
                    <FormItems>
                        <label htmlFor="newsEmail">
                            E-mail*
                        </label>
                        <FormInput
                            id="newsEmail"
                            name="email"
                            type="email"
                            value={email}
                            onChange={(event) => setEmail(event.target.value)}
                            onBlur={emailValidator}

                        />
                        {errorEmail && <Error>{errorEmail}</Error>}
                    </FormItems>
                    <DivSubmit>
                        <Submit> 
                            Envoyer
                        </Submit>
                    </DivSubmit> 
                </Col>
            </Wrapper>
        </Container>
    )
}
