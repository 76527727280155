import React from 'react'
import landscape from '../assets/PolitiquesDeConfidentialte.webp'
import landscapeTel from '../assets/landscape1Tel.webp'
import styled from 'styled-components'
import { Container, Row } from 'react-bootstrap'
import { Newsletter } from '../components/Newsletter'
import { Helmet } from 'react-helmet'

const AProposWrapper = styled.div`
    overflow-x: hidden;
`
const Landscape = styled.div`
    padding: 0px;
    margin: 0px;
    background-image: url(${landscape});
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 70vh;
    position: relative;
    z-index: -1;
    @media (max-width: 570px) {
        background-image: url(${landscapeTel});
    }
`
const LandscapeWrapper = styled.div`
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    position: absolute;
    display: flex;
    align-items: center;
    padding: 5%;
    color: #fff;
    line-height: 1.5em;
    top: 0;
    left: 0;
`
const LandscapeContainer = styled.div`
    width: 60%;
    @media (max-width: 785px) {
        width: 85% !important;
    }
    @media (max-width: 490px) {
        width: 95% !important;
    }
`
const MyRow = styled(Row)`
    padding-left: 50px;
    padding-right: 50px;
    text-align: justify;
    @media (max-width: 785px) {
        padding-left: 30px;
        padding-right: 30px;
    }
    @media (max-width: 490px) {
        padding-left: 15px;
        padding-right: 15px;
    }
`

export default function PolitiquesDeConfidentalite() {
    return (
        <AProposWrapper>
            <Helmet>
                <title>Tout savoir sur les livreurs Gochap</title>
                <meta
                    name="description"
                    content="Découvrez nos Gochap et commencez à faire des livraisons partout à Abidjan"
                />
            </Helmet>
            <Landscape>
                <LandscapeWrapper>
                    <LandscapeContainer>
                        <p className="fw-bold text-capitalize fs-1 lh-sm">
                            Politiques de confidentialité
                        </p>
                        <p className="fs-4">
                            Faites confiance à notre service de livraison fiable
                            pour envoyer vos colis en toute sécurité.
                        </p>
                    </LandscapeContainer>
                </LandscapeWrapper>
            </Landscape>
            <Container className="mb-2 mt-5" fluid>
                <p className="text-center">
                    {' '}
                    <span className="fs-2 fw-bold">Article 1 : </span>
                    <span className="fs-3">Préambule</span>
                </p>
                <MyRow>
                    Gochap (ci-après, l’"Application") est un site
                    web/application mobile proposant divers services à ses
                    utilisateurs (ci-après, les "Utilisateurs"). <br /> <br />
                    L’Application est soucieux de la protection des données
                    personnelles des Utilisateurs et s'engage à les traiter dans
                    le respect des dispositions légales et réglementaires
                    applicables, notamment la Loi N° 2013-450 du 19 juin 2013
                    relative à la protection des données à caractère personnel.{' '}
                    <br />
                    <br />
                    La présente politique de confidentialité (ci-après, la
                    "Politique") a pour objet d'informer les Utilisateurs du
                    traitement de leurs données personnelles effectué par
                    l’Application.
                </MyRow>
            </Container>
            <Container className="my-5" fluid>
                <p className="text-center">
                    {' '}
                    <span className="fs-2 fw-bold">Article 1 : </span>
                    <span className="fs-3">
                        Collecte des données personnelles
                    </span>
                </p>
                <MyRow>
                    <p>
                        L’Application collecte des données personnelles
                        relatives aux Utilisateurs lors de leur inscription sur
                        l’Application, de leur utilisation des services de
                        l’Application et de leurs contacts avec l’Application.{' '}
                        <br />
                        <br />
                        Les données personnelles collectées peuvent inclure :{' '}
                        <br />
                    </p>
                    <ul className='ms-5'>
                        <li className="my-2">
                            Les données d'identification : nom, prénom, adresse
                            électronique, mot de passe, etc. ;
                        </li>
                        <li className="my-2">
                            Les données de contact numéro de téléphone, ville,
                            adresse, etc. ;
                        </li>
                        <li className="my-2">
                            Les données de navigation: adresse IP, pages
                            consultées, dates et heures de connexion, etc. ;
                        </li>
                        <li className="my-2">
                            Les données techniques: type de terminal, système
                            d'exploitation, navigateur, etc.
                        </li>
                    </ul>
                    <br />
                    <p>
                        L’Application ne collecte aucune donnée personnelle
                        sensible, telle que des informations relatives à la
                        race, à l'origine ethnique, aux opinions politiques, aux
                        croyances religieuses, à la santé ou à l'orientation
                        sexuelle.
                    </p>
                </MyRow>
            </Container>
            <Container className="my-5" fluid>
                <p className="text-center">
                    {' '}
                    <span className="fs-2 fw-bold">Article 2 : </span>
                    <span className="fs-3">
                        Utilisation des données personnelles
                    </span>
                </p>
                <MyRow className="mb-5">
                    <p>
                        L’Application utilise les données personnelles des
                        Utilisateurs pour les finalités suivantes :
                    </p>

                    <ul className='ms-5'>
                        <li className="my-2">
                            Gérer les comptes des Utilisateurs et leur fournir
                            les services de l’Application.
                        </li>
                        <li className="my-2">
                            Améliorer les services de l’Application et proposer
                            des contenus personnalisés aux Utilisateurs.
                        </li>
                        <li className="my-2">
                            Prévenir et lutter contre la fraude et les abus.
                        </li>
                        <li className="my-2">
                            Respecter les obligations légales et réglementaires.
                        </li>
                    </ul>
                    <p>
                        L’Application ne traite les données personnelles des
                        Utilisateurs que pour les finalités strictement
                        nécessaires à l'accomplissement de ces objectifs.
                    </p>
                </MyRow>
            </Container>
            <Container className="mb-2 mt-5" fluid>
                <p className="text-center">
                    {' '}
                    <span className="fs-2 fw-bold">Article 3 : </span>
                    <span className="fs-3">
                        Conservation des données personnelles
                    </span>
                </p>
                <MyRow>
                    L’Application conserve les données personnelles des
                    Utilisateurs pendant une durée n'excédant pas celle
                    nécessaire à l'accomplissement des finalités pour lesquelles
                    elles ont été collectées. <br />
                    <br />
                    Ainsi, les données d'identification et de contact des
                    Utilisateurs sont conservées pendant toute la durée de leur
                    inscription sur l’Application. Les données de navigation
                    sont conservées pendant une durée d’un mois.
                </MyRow>
            </Container>
            <Container className="mb-2 mt-5" fluid>
                <p className="text-center">
                    {' '}
                    <span className="fs-2 fw-bold">Article 4 : </span>
                    <span className="fs-3">
                        Communication des données personnelles
                    </span>
                </p>
                <MyRow>
                    <p className="mb-4">
                        L’Application ne communique les données personnelles des
                        Utilisateurs à des tiers qu'en cas de nécessité absolue,
                        notamment :
                    </p>
                    <ul className="ms-4 mb-4">
                        <li className="my-2">
                            Aux prestataires de services de l’Application, dans
                            le cadre de l'exécution de leurs prestations.
                        </li>
                        <li className="my-2">
                            Aux autorités judiciaires ou administratives, dans
                            le cadre de requêtes officielles.
                        </li>
                    </ul>
                    <p>
                        L’Application ne procède à aucun transfert de données
                        personnelles des Utilisateurs vers des pays tiers.
                    </p>
                </MyRow>
            </Container>
            <Container className="mb-2 mt-5" fluid>
                <p className="text-center">
                    {' '}
                    <span className="fs-2 fw-bold">Article 5 : </span>
                    <span className="fs-3">Droits des Utilisateurs</span>
                </p>
                <MyRow>
                    <p className="mb-4">
                        Les Utilisateurs disposent des droits suivants en ce qui
                        concerne leurs données personnelles :
                    </p>
                    <ul className="ms-4 pl-4 mb-4">
                        <li className="my-2">
                            Droit d'accès : les Utilisateurs ont le droit de
                            demander à l’Application de leur communiquer les
                            données personnelles qu'il traite les concernant.
                        </li>
                        <li className="my-2">
                            Droit de rectification : les Utilisateurs ont le
                            droit de demander à l’Application de rectifier les
                            données personnelles les concernant qui sont
                            inexactes ou incomplètes.
                        </li>
                        <li className="my-2">
                            Droit à l'effacement : les Utilisateurs ont le droit
                            de demander à l’Application d'effacer les données
                            personnelles les concernant dans les cas prévus par
                            la Loi N° 2013-450 du 19 juin 2013 relative à la
                            protection des données à caractère personnel.
                        </li>
                        <li className="my-2">
                            Droit à la limitation du traitement : les
                            Utilisateurs ont le droit de demander à
                            l’Application de limiter le traitement de leurs
                            données personnelles dans les cas prévus par la Loi
                            N° 2013-450 du 19 juin 2013 relative à la protection
                            des données à caractère personnel.
                        </li>
                        <li className="my-2">
                            Droit d'opposition au traitement : les Utilisateurs
                            ont le droit de s'opposer au traitement de leurs
                            données personnelles par l’Application dans les cas
                            prévus par la Loi N° 2013-450 du 19 juin 2013
                            relative à la protection des données à caractère
                            personnel.
                        </li>
                        <li className="my-2">
                            Droit à la portabilité des données : les
                            Utilisateurs ont le droit de recevoir les données
                            personnelles qu'ils ont fournies à l’Application
                            dans un format structuré, couramment utilisé et
                            lisible par machine, et de les transmettre à un
                            autre responsable du traitement dans les cas prévus
                            par la Loi N° 2013-450 du 19 juin 2013 relative à la
                            protection des données à caractère personnel.
                        </li>
                    </ul>
                    <p>
                        Pour exercer leurs droits, les Utilisateurs peuvent
                        contacter l’Application par courrier électronique à
                        l'adresse{' '}
                        <a
                            href="mailto:service.client@gochap.ci"
                            className="text-primary"
                        >
                            service.client@gochap.ci
                        </a>
                        .
                    </p>
                </MyRow>
            </Container>
            <Container className="mb-2 mt-5" fluid>
                <p className="text-center">
                    {' '}
                    <span className="fs-2 fw-bold">Article 6 : </span>
                    <span className="fs-3">
                        Sécurité des données personnelles
                    </span>
                </p>
                <MyRow>
                    <p>
                        L’Application met en œuvre les mesures de sécurité
                        techniques et organisationnelles appropriées pour
                        protéger les données personnelles des Utilisateurs
                        contre les altérations, les destructions et les
                        diffusions non autorisées. <br />
                        <br />
                        L’Application s'engage à respecter les principes de
                        protection des données dès la conception et par défaut.
                    </p>
                </MyRow>
            </Container>
            <Container className="mb-2 mt-5" fluid>
                <p className="text-center">
                    {' '}
                    <span className="fs-2 fw-bold">Article 7 : </span>
                    <span className="fs-3">Modification de la Politique</span>
                </p>
                <MyRow>
                    <p>
                        {' '}
                        L’Application peut modifier la présente Politique à tout
                        moment. Les modifications de la Politique seront
                        notifiées aux Utilisateurs par courrier électronique ou
                        par publication sur du site internet.
                    </p>
                </MyRow>
            </Container>
            <Newsletter />
        </AProposWrapper>
    )
}
