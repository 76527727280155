import React, { useState } from 'react'
import { Container, Image } from 'react-bootstrap'
import styled from 'styled-components'
import ImageLivreur from "../assets/livreur1.webp"
import { useFormik } from 'formik'
import * as Yup from 'yup'

const Wrapper = styled(Container)`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    background-color: white;
    padding: 0;
`
const Title = styled.h1`
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: 900;
    @media (max-width: 600px) {
        margin-top: 10px;
    }
`
const SubTitle = styled.span`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
`
const SendAgain = styled.button`
    color: black;
    margin-top: 0.5rem;
    text-decoration: underline;
    background: transparent;
    border: 0;
    transition: all 500ms;
    &:hover {
        color: #fd9f1b;
        transform: scale(1.1);
    }
`
const Description = styled.p`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60%;
    text-align: center;
`

const CadreLeft = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 40%;
    background-color: white;
    @media (max-width: 496px) {
        width: 100%;
    }
`
const CadreRight = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 60%;
    background-color: white;
    @media (max-width: 496px) {
        width: 100%;
    }
`

const MyForm = styled.form`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`
const FormItems = styled.div`
    display: flex;
    width: 60%;
    margin-bottom: 10px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    @media (max-width: 496px) {
        width: 80%;
    }
    @media (max-width: 400px) {
        width: 90%;
    }
`
const FormInput = styled.input`
    margin-top: 5px;
    height: 55px;
    width: 100%;
    background-color: #EEEEEE;
    border-radius: 15px;
    padding-left: 10px;
    border: 0;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    @media (max-width: 600px) {
        height: 45px;
    }
`
const Submit = styled.button`
    padding: 0.75em;
    height: 55px;
    width: 60%;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 1.2em;
    text-transform: uppercase;
    color: #fff;
    background-color: #fd9f1b;
    border-radius: 10px;
    border: 0;
    transition: all 0.1s ease-in-out;
    &:hover {
        background-color: #fff;
        color: #fd9f1b;
        background-position: -3.75em;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    }
    @media (max-width: 600px) {
        font-size: 0.8em;
        height: 45px;
    }
    @media (max-width: 496px) {
        width: 80%;
    }
    @media (max-width: 400px) {
        width: 90%;
    }
`
const validationSchema = Yup.object().shape({
    MycodeDeConfirmation: Yup.string()
        .required('Veuillez entrer le code')
        .matches(
            /^(?=.*[a-zA-Z0-9]).{6,}/,
            'Doit contenir 6 caractères'
        ),
})

const Error = styled.p`
    font-size: 0.8em;
    color: red;
    margin-left: 10px;
    margin-top: 10px;
`

const initialValues = {
    MycodeDeConfirmation: '',
}

export const CodeDeConfirmation = () => {
    const [myCode, setMyCode] = useState('')

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values) => {
            console.log('MyForm data', values)
        },
    })

    return (
        <Wrapper fluid>
            <CadreLeft>
                <Image style = {{width:"100%", height:"100%"}} src = {ImageLivreur} alt = "Livreur GOChap"/>
            </CadreLeft>
            <CadreRight>
                <Title>Code De Confirmation</Title>
                <Description>Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien.</Description>
                <MyForm
                    onSubmit={formik.handleSubmit}
                    className="mt-2 mb-5"
                >
                    <FormItems className="mt-3">
                        <FormInput
                            placeholder="Entrez le code"
                            name="MycodeDeConfirmation"
                            id="MycodeDeConfirmation"
                            onChange={(e) => setMyCode(e.target.value)}
                            value={myCode}
                            {...formik.getFieldProps('MycodeDeConfirmation')}
                            required
                        />
                        {formik.errors.MycodeDeConfirmation &&
                            formik.touched.MycodeDeConfirmation && (
                                <Error>
                                    {formik.errors.MycodeDeConfirmation}
                                </Error>
                            )
                        }
                            
                    </FormItems>
                    <Submit className="mt-1" type="submit">
                        Valider
                    </Submit>
                    <SubTitle>je n'ai pas reçu</SubTitle>
                    <SendAgain>Renvoyer le code</SendAgain>
                </MyForm>
            </CadreRight>
        </Wrapper>
    )
}
