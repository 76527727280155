import React, { createContext, useState, useEffect, useCallback } from 'react'

const CountdownContext = createContext()

const CountdownProvider = ({ children }) => {
  const initialTimeLeft = localStorage.getItem('countdownTimeLeft') || 120
  const [timeLeft, setTimeLeft] = useState(parseInt(initialTimeLeft, 10))
  const [isButtonActive, setButtonActive] = useState(false)

  const resetTimer = useCallback(() => {
    setTimeLeft(120)
    setButtonActive(false)
  }, [])


  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 0) {
          clearInterval(timer)
          setButtonActive(true)
          return 0
        }
        return prevTime - 1
      })
    }, 1000)
    localStorage.setItem('countdownTimeLeft', timeLeft)
    return () => {
      clearInterval(timer)
    };
  }, [timeLeft]);

  return (
    <CountdownContext.Provider value={{ timeLeft, isButtonActive, resetTimer, setTimeLeft, setButtonActive }}>
      {children}
    </CountdownContext.Provider>
  );
};

export { CountdownProvider, CountdownContext }
