import React from 'react'
import { Container, Row } from 'react-bootstrap'
import styled from 'styled-components'

const Wrapper = styled(Container)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px;
    height: 40rem;
    background-color: skyblue;
`
const MyCadre = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 10rem;
    border-radius: 15px;
    background-color: white;
    backdrop-filter: blur(10px);
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.5);
    font-size: 1.5em;
`
const MyLinkAccueil = styled.a`
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 10px;
    color: black;
    background-color: #fd9f1b;
    font-size: 1em;
    font-weight: bold;
    width: 8rem;
    height: 2.5rem;
    text-decoration: none;
    transition: all 500ms;
    &:hover {
        color: white;
        background-color: #fd9f1b;
        transform: scale(1.1);
    }
`

const EndSignUp = () => {
    return (
        <Wrapper fluid>
            <Row>
                <MyCadre>
                    Vous êtes bien inscrits !
                    <MyLinkAccueil href='/' className='mt-4'>ACCUEIL</MyLinkAccueil>
                </MyCadre>
            </Row>
        </Wrapper>
    )
}

export default EndSignUp